.wrapper {
    border: none;
    border-radius: var(--border-radius-1);
    margin-block-start: 15em;
    margin-inline: auto;
    padding: 0;

    &::backdrop {
        background-color: rgba(0, 0, 0, 0.55);
    }
}

.head {
    padding-block: var(--space-xs);
    padding-inline: var(--space-sm);
    background-color: var(--ui-gray-2);

    > h1 {
        font-size: 1.125rem;
    }
}

.paragraph {
    margin-block-start: var(--space-sm);
    margin-inline: var(--space-sm);
}

.button {
    margin: var(--space-sm);
    margin-block-start: var(--space-md);
    background-color: var(--ui-black);
    color: var(--ui-white);
    padding-block: var(--space-xs);
    padding-inline: var(--space-sm);

    // mobile-only
    &:focus {
        outline: none;
    }
}
