@mixin custom-scrollbar($trackColor: #ddd, $thumbColor: #ccc, $size: 5px) {
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        width: $size;
        height: $size;

        &-track {
            background-color: $trackColor;
            border-radius: 20px;
        }

        &-thumb {
            background-color: $thumbColor;
            border-radius: 20px;
        }
    }
}

@mixin invisible-scrollbar() {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border: none;
    }
}

@mixin button-base() {
    border: 2px solid transparent;
    background-color: transparent;
    border-radius: var(--border-radius-1);

    &:disabled {
        pointer-events: none;
    }
}
