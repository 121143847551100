input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 88%;
    padding-block: var(--space-xs);

    &:hover {
        &::-webkit-slider-runnable-track {
            background-color: var(--ui-gray-4);
        }
    }

    &:focus {
        outline: none;
    }

    &:focus-visible {
        &::-webkit-slider-thumb {
            outline: 1px solid var(--ui-black);
            outline-offset: 0.25rem;
        }
    }

    &::-webkit-slider-runnable-track {
        height: 2px;
        background-color: var(--ui-gray-3);
        border-radius: 1px;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none; // Override default look
        appearance: none;
        margin-block-start: -9px; // Centers thumb on the track
        background-color: var(--ui-black);
        box-shadow: none;
        border-radius: 50%;
        height: 20px;
        width: 20px;
    }

    // Firefox styles

    &::-moz-range-track {
        background-color: var(--ui-gray-3);
        border-radius: 1px;
        height: 2px;
    }

    &::-moz-range-thumb {
        background-color: var(--ui-black);
        border: none; // Removes extra border
        border-radius: 50%;
        height: 20px;
        width: 20px;
    }

    &:focus::-moz-range-thumb {
        outline: 3px solid var(--ui-black);
        outline-offset: 0.125rem;
    }
}
