body {
    min-height: 100vh;
    text-rendering: optimizeLegibility;
    font-family: "IBM Plex Sans", sans-serif;
    color: var(--ui-black);
    background-color: var(--ui-gray-1);

    &:has(dialog[open]) {
        overflow: hidden;
    }
}

h1,
h2,
h3,
h4,
button {
    font-weight: 500;
}

button {
    @include button-base();
    cursor: pointer;
    font-size: 1rem;

    &:disabled {
        cursor: default;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
