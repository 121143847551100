.wrapper {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;

    @media (max-width: 60em) {
        padding-block: var(--space-md);
        gap: var(--space-sm);
    }
}
