.control {
    cursor: pointer;
    display: inline-block;
    position: relative;

    // Improve the clickable area
    &:before {
        content: "";
        position: absolute;
        inset: calc(var(--space-xs) * -1);
    }

    &:hover {
        .content {
            --controlAccent: var(--ui-gray-4);
        }
    }

    &:has([type="radio"]:checked) {
        cursor: default;
    }
}

.input {
    position: absolute;
    opacity: 0;
    z-index: -1;

    &:is([type="radio"]) ~ .content {
        &:after {
            content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 17' width='17' height='17'%3E %3Crect fill='%23fff' x='2.5' y='2.5' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -3.5208 8.5)' width='12' height='12'/%3E %3Crect x='6' y='6' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -3.5208 8.5)' width='5' height='5'/%3E %3C/svg%3E");
            position: absolute;
            inset-block-start: -9px;
            inset-inline-end: -8px;
            pointer-events: none;
            transform: scale(0);
            transition: transform ease 200ms;
        }
    }

    // Display color blocks in gray
    // when they are unchecked
    &:not(:checked) ~ .content {
        --red: var(--ui-gray-1);
        --blue: var(--ui-gray-1);
        --yellow: var(--ui-gray-1);
        --black: var(--ui-gray-1);
    }

    &:checked {
        ~ .content {
            --controlAccent: var(--ui-black);
        }

        &:is([type="radio"]) {
            ~ .content:after {
                transform: scale(1);
            }
        }
    }

    &:focus-visible ~ .content {
        outline: 1px solid var(--ui-black);
        outline-offset: 2px;
    }
}

.content {
    --controlAccent: var(--ui-gray-3);
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-xs);
    border: 1px solid transparent;

    .labelContainer {
        position: relative;
        color: var(--controlAccent);
        height: var(--space-md);

        > span {
            position: absolute;
            transform: translateX(-50%);
            background-color: var(--ui-white);
        }
    }
}
