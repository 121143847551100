@import "./scss/maps";

:root {
    // Colors
    // E.g. --ui-white: #FFFEFB;
    @each $key, $value in $colors {
        --#{$key}: #{$value};
    }

    // Spacing
    // E.g. --space-xxs: 4px;
    @each $key, $value in $space {
        --space-#{$key}: #{$value};
    }

    // Border radius
    --border-radius-1: 6px;
}
