.wrapper {
    height: 100vh;
    display: grid;
    gap: var(--space-sm);
    grid-template-areas:
      "head head"
      "aside main"
      "aside main";
    grid-template-rows: var(--space-xxl) 1fr;
    grid-template-columns: 280px 1fr;

    @media (max-width: 60em) {
        display: block;
        max-width: 35em;
        margin: auto;
    }
}

.child {
    contain: layout style;
    user-select: none;

    &:where(header) {
        grid-area: head;
        position: relative;
        z-index: 1;
    }

    &:where(aside) {
        grid-area: aside;
        margin: 0 0 var(--space-sm) var(--space-sm);
    }

    &:where(main) {
        grid-area: main;
        align-self: stretch;
        margin: 0 var(--space-sm) var(--space-sm) 0;
    }

    @media (max-width: 60em) {
        margin: 0;
    }
}
