.wrapper {
    position: relative;
}

.tooltip {
    position: absolute;
    inset-inline-start: 50%;
    width: max-content;
    max-width: 230px;
    color: var(--ui-white);
    background-color: var(--ui-black);
    padding: var(--space-md);
    border-radius: var(--border-radius-1);

    opacity: 0;
    animation: reveal-tooltip ease 150ms forwards;

    &:after {
        content: "";
        position: absolute;
        inset-inline-start: 50%;
        transform: translateX(-50%);
        border: solid transparent;
        height: 0;
        width: 0;
        border-width: var(--space-xs);
    }

    &.top {
        inset-block-end: calc(100% + var(--space-md));
        transform: translateX(-50%);

        &:after {
            inset-block-start: 100%;
            border-block-start-color: var(--ui-black);
        }
    }

    &.bottom {
        inset-block-start: calc(100% + var(--space-md));
        transform: translateX(-50%);

        &:after {
            inset-block-end: 100%;
            border-block-end-color: var(--ui-black);
        }
    }
}

.content {
    + .content {
        margin-block-start: var(--space-sm);
    }

    .title {
        font-size: 0.875rem;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        &:empty {
            display: none;
        }
    }

    a {
        color: var(--ui-white);
        text-underline-offset: 2px;
    }
}

@keyframes reveal-tooltip {
    to {
        opacity: 1;
    }
}
