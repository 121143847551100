.form {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 0.875rem;
    letter-spacing: 0.1px;

    h3 {
        font-size: 1rem;

        &:has(+ fieldset) {
            margin-block-end: var(--space-sm);
        }

        &.hasErrors {
            color: var(--red);
        }
    }
}

.inner {
    flex: 1;
    overflow: auto;
}

.section {
    padding: var(--space-sm);
    border-block-end: 1px solid var(--ui-gray-2);

    &:last-of-type {
        border-block-end: none;
    }

    > fieldset {
        border: 0;
        padding: 0;

        display: flex;
        gap: var(--space-md);
        justify-content: center;
    }
}

.buttonContainer {
    padding: var(--space-sm) var(--space-xs) var(--space-xs);

    > button {
        width: 100%;
        height: var(--space-xxl);
        background-color: var(--ui-black);
        color: var(--ui-white);

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        > svg {
            fill: var(--yellow);
            transition: fill ease 150ms;
        }

        &:disabled {
            background-color: var(--ui-gray-3);

            svg {
                fill: var(--ui-gray-4);
            }
        }

        &:hover {
            background-color: var(--ui-gray-5);

            .shape1,
            .shape2,
            .shape3 {
                animation: shine ease 700ms;
            }

            .shape2 {
                animation-delay: 80ms;
            }

            .shape3 {
                animation-delay: 160ms;
            }
        }

        &:focus-visible {
            outline: 2px solid var(--ui-black);
            outline-offset: 2px;
        }

        &:active {
            border-color: var(--ui-white);

            > svg {
                transform: scale(0.8);
            }
        }
    }
}

.rangeLabels {
    margin-block-start: var(--space-xs);
    display: flex;
    justify-content: space-between;
}

@keyframes shine {
    20% {
        opacity: 0;
    }

    80% {
        opacity: 1;
    }
}
