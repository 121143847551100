.wrapper {
    position: relative;
    height: 100%;
    background-color: var(--ui-white);
    border-radius: var(--border-radius-1);
}

.nav {
    background-color: var(--ui-gray-2);
    border-radius: var(--border-radius-1) var(--border-radius-1) 0 0;
    height: var(--space-xl);
    display: flex;
}

.title {
    flex: 1;
    align-self: center;
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-inline-start: var(--space-sm);
}

.button {
    height: 100%;
    width: var(--space-xl);
    transition: background-color ease 150ms;

    > svg {
        fill: var(--ui-gray-5);
    }

    &:disabled {
        > svg {
            fill: var(--ui-gray-3);
        }
    }

    &:hover {
        background-color: var(--ui-gray-3);
    }

    &:active {
        > svg {
            transform: scale(0.9);
        }
    }

    &.highlighted {
        animation: highlight-button step-end 100ms;

        > svg {
            transform: scale(0.9);
        }
    }
}

.body {
    position: absolute;
    inset: 0;
    inset-block-start: var(--space-xl);
    contain: content;

    @media (max-width: 60em) {
        position: static;
    }
}

@keyframes highlight-button {
    from {
        background-color: var(--ui-gray-3)
    }

    to {
        background-color: unset;
    }
}
