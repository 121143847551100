.gridContainer {
    align-self: center;
    container: artwork-grid / inline-size;
}

.grid {
    --mainBorder: 8px;

    display: grid;
    gap: var(--mainBorder);
    grid-template-columns: repeat(var(--columns), 1fr);
    grid-template-rows: repeat(var(--rows), 1fr);

    aspect-ratio: var(--aspectRatio, 1);
    max-height: min(70vh, 600px);
    max-width: min(75%, 1000px);
    margin: auto;
    background-color: var(--black);
}

.block {
    background-color: var(--blockColor, var(--white));
    grid-column: span var(--blockColumns);
    grid-row: span var(--blockRows);

    $colors: "yellow", "red", "blue", "black";

    @each $color in $colors {
        &.#{$color} {
            background-color: var(--#{$color});
        }
    }
}

@container artwork-grid (max-width: 600px) {
    .grid {
        --mainBorder: 6px;
    }
}

@container artwork-grid (max-width: 400px) {
    .grid {
        --mainBorder: 3px;
    }
}
