.wrapper {
    height: 100%;
    color: var(--ui-gray-4);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--space-lg);

    @media (max-width: 60em) {
        margin-block: var(--space-lg);
        padding-inline: var(--space-xl);
    }
}

.copy {
    order: 1;
    text-align: center;
    max-width: 600px;

    > h3 {
        font-size: 1.375rem;
        margin-block-end: var(--space-xs);
    }

    > p {
        font-size: 1.125rem;
    }
}

.placeholder {
    --placeholderWidth: 412px;
    --playState: running;

    > g {
        animation: art-transition step-start 3200ms infinite;
        animation-play-state: var(--playState);
    }

    .empty {
        fill: var(--white);
    }

    .filled {
        fill: var(--ui-gray-2);
    }

    .track {
        fill: var(--ui-gray-3);
    }

    @media (max-width: 60em) {
        height: auto;
    }
}

@keyframes art-transition {
    25% {
        transform: translateX(calc(var(--placeholderWidth) * -1));
    }

    50% {
        transform: translateX(calc(var(--placeholderWidth) * -2));
    }

    75% {
        transform: translateX(calc(var(--placeholderWidth) * -3));
    }
}
