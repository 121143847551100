.wrapper {
    height: 100%;
    padding: 0 var(--space-sm);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--ui-black);
    color: var(--ui-white);

    > h1 {
        font-size: 1.125rem;
    }

    @media (max-width: 60em) {
        flex-direction: column;
        justify-content: center;
        padding-block: var(--space-xs);
    }
}

.buttons {
    display: flex;
    gap: var(--space-sm);

    button {
        color: var(--ui-white);
        font-size: 0.875rem;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        padding: var(--space-xxs) var(--space-xs);

        > span {
            display: inline-block;
        }

        &:hover {
            background-color: var(--ui-gray-5);
        }

        &:active {
            > span {
                transform: scale(0.95);
            }
        }

        &:focus-visible {
            outline: 0;
            border-color: var(--ui-gray-4);
        }
    }
}

.locPanel {
    padding-left: var(--space-sm);
    border-left: 1px solid var(--ui-gray-4);

    > button {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            background-color: var(--ui-white);
            height: 2px;
            width: 80%;
            inset-block-end: 1px;
            inset-inline-start: 10%;
            transition: transform ease 200ms;
            transform: scale(0, 1);
            transform-origin: left;
        }

        &.isCurrent {
            &:after {
                transform: scale(1);
            }
        }
    }
}
